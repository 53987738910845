import { ref, onMounted } from '@vue/composition-api'
import { i18n } from '@/plugins/i18n'
import poOnline from '@/api/DrugManagement/poOnline'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import store from '@/store'
import router from '@/router'

export default function usePoOnline() {
  const columns = ref([
    {
      text: '#',
      align: 'start',
      sortable: false,
      value: 'purchase_id_pri',
      width: 30,
    },
    {
      text: i18n.t('a_number'),
      value: 'purchase_id',
      width: 120,
    },
    {
      text: i18n.t('dealer'),
      value: 'purchase_from_name',
      width: 200,
    },
    {
      text: i18n.t('price'),
      value: 'purchase_price',
      width: 100,
      align: 'right',
    },
    {
      text: i18n.t('dateCreate'),
      value: 'purchase_date',
      width: 160,
    },
    {
      text: i18n.t('dateImport'),
      value: 'purchase_date_import',
      width: 160,
    },
    {
      text: i18n.t('status'),
      value: 'purchase_status_id',
      width: 100,
      align: 'center',
    },
    {
      text: i18n.t('user_is_list'),
      value: 'users',
      width: 250,
    },
    {
      text: i18n.t('option'),
      value: 'actions',
      width: 120,
      align: 'center fixed',

    },
  ])
  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const segmentId = ref(0)
  const totalPage = ref(0)
  const isManagePO = ref(false)
  const options = ref({})
  const loading = ref(false)
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const searchText = ref('')
  const dataManagePO = ref({})
  const isShowConfirmImport = ref(false)
  const idConfirmImport = ref('')
  const commentConfirmImport = ref('')
  const payComment = ref('')
  const importComment = ref('')
  const idConfirmPay = ref('')
  const idCancelPo = ref('')
  const isShowConfirmPay = ref(false)
  const isCreatePO = ref(false)
  const isShowCancelPo = ref(false)
  const isShowDetailPo = ref(false)
  const isShowAlertConfirmCancel = ref(false)
  const dataDetailPo = ref({})

  onMounted(() => {
    fetchDataTable()
  })

  const fetchDataTable = async () => {
    loading.value = true
    const { data, count, segment, count_of_page } = await poOnline.poList({
      searchtext: searchText.value,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    })
    dataTableList.value = data
    totalDataTableList.value = count
    segmentId.value = segment
    totalPage.value = count_of_page
    loading.value = false
  }

  const showConfirmPay = (item) => {
    idConfirmPay.value = item.purchase_id_pri
    payComment.value = item.purchase_comment

    isShowConfirmPay.value = true
  }

  const confirmPay = async () => {
    const res = await poOnline.poConfirmPay({
      purchase_id_pri: idConfirmPay.value,
      purchase_comment: payComment.value,
    })
    store.commit('app/ALERT', {
      message: res.message,
      color: res.response ? 'success' : 'error',
    })
    isShowConfirmPay.value = false
    fetchDataTable()

  }

  const onCancelPo = async (_, comment) => {
    const res = await poOnline.poCancel({
      purchase_id_pri: idCancelPo.value,
      purchase_comment: comment,
    })
    store.commit('app/ALERT', {
      message: res.message,
      color: res.response ? 'success' : 'error',
    })
    isShowCancelPo.value = false
    fetchDataTable()
  }

  const confirmImport = async (userId, comment) => {
    const res = await poOnline.poConfirmImport({
      purchase_id_pri: idConfirmImport.value,
      purchase_comment: comment,
    })
    store.commit('app/ALERT', {
      message: res.message,
      color: res.response ? 'success' : 'error',
    })
    fetchDataTable()
  }

  const print = id => {
    const ids = [id]
    const routeData = router.resolve({
      name: 'poPreview',
      query: {
        id: String(ids),
      },
    })
    window.open(routeData.href, '_blank')
  }

  return {
    confirmImport,
    confirmPay,
    print,
    idCancelPo,
    isShowCancelPo,
    isShowConfirmImport,
    idConfirmImport,
    commentConfirmImport,
    dataManagePO,
    isManagePO,
    isCreatePO,
    columns,
    importComment,
    idConfirmPay,
    payComment,
    isShowConfirmPay,
    isShowDetailPo,
    dataDetailPo,
    isShowAlertConfirmCancel,
    onCancelPo,
    showConfirmPay,
    fetchDataTable,
    dataTableList,
    options,
    loading,
    searchText,
    footer,
    totalDataTableList,
    segmentId,
    totalPage,
  }
}
