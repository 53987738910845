import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const poList = async body => {
  await refreshToken()
  const response = await api
    .post({
      path: 'purchaseonline/list',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('GET PO ERROR : ', err)

      return []
    })

  return response
}
const poDrugList = async body => {
  await refreshToken()
  const response = await api
    .post({
      path: 'purchaseonline/drug',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('GET PO DRUG ERROR : ', err)

      return []
    })

  return response
}

const poAddDrugToCart = async body => {
  await refreshToken()
  const response = await api
    .post({
      path: 'purchaseonline/cart',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('GET ADD CART ERROR : ', err)

      return []
    })

  return response
}

const poCartList = async body => {
  await refreshToken()
  const response = await api
    .post({
      path: 'purchaseonline/cartlist',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('GET CART LIST ERROR : ', err)

      return []
    })

  return response
}


const poRemoveCart = async id => {
  const response = await api
    .delete({
      path: `purchaseonline/cart/${id}`,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('REMOVE CART ERROR : ', err)

      return []
    })

  return response
}

const poCreate = async body => {
  const response = await api
    .post({
      path: `purchaseonline`,
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('ADD PO ERROR : ', err)

      return []
    })

  return response
}

const poUpdate = async (body) => {
  const response = await api
    .put({
      path: `purchaseonline`,
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('UPDATE PO ERROR : ', err)

      return []
    })

  return response
}

const poRemoveDrugInCartPo = async (poId, cartId) => {
  const response = await api
    .delete({
      path: `purchaseonline/${poId}/${cartId}`,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('REMOVE CART IN PO ERROR : ', err)

      return []
    })

  return response
}

const poUpdateStatusConfirm = async (body) => {
  const response = await api
    .put({
      path: `purchaseonline/confirm`,
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('UPDATE STATUS CONFIRM ERROR : ', err)

      return {}
    })

  return response
}

const poConfirmPay = async (body) => {
  const response = await api
    .put({
      path: `purchaseonline/pay`,
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('UPDATE STATUS PAYMENT ERROR : ', err)

      return {}
    })

  return response
}

const poConfirmImport = async (body) => {
  const response = await api
    .put({
      path: `purchaseonline/import`,
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('UPDATE STATUS CONFIRM ERROR : ', err)

      return {}
    })

  return response
}

const poCancel = async (body) => {
  const response = await api
    .put({
      path: `purchaseonline/cancel`,
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('CANCEL PO ERROR : ', err)

      return {}
    })

  return response
}

const poGetById = async (id) => {
  const response = await api
    .get({
      path: `purchaseonline/${id}`,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('GET PO ERROR : ', err)

      return {}
    })

  return response
}

export default {
  poGetById,
  poCancel,
  poConfirmPay,
  poConfirmImport,
  poRemoveDrugInCartPo,
  poUpdateStatusConfirm,
  poUpdate,
  poCreate,
  poList,
  poDrugList,
  poAddDrugToCart,
  poCartList,
  poRemoveCart,
}
