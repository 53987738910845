<template>
  <div>
    <v-dialog v-model='isManagePo' persistent max-width='1000'>
      <v-card>
        <v-card-title>
          <span>
            {{ $t('editPoOnline') }} <v-chip small color='secondary'>{{ poData.purchase_id
            }}</v-chip>
          </span>
          <v-spacer></v-spacer>
          <v-autocomplete
            v-model='dealerSelected'
            outlined
            dense
            :label="$t('selectDealer')"
            :items='drugfromList'
            disabled
            hide-details='auto'
            style='max-width: 350px'
            return-object
            :no-data-text="$t('no_information')"
            item-value='drugfrom_id'
            item-text='drugfrom_name'
          >
          </v-autocomplete>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class='pt-1 pb-0'>
          <h4 class='font-weight-bold text-decoration-underline'>
            {{ $t('dealerInformation') }}
          </h4>
          <v-row>
            <v-col cols='12' md='6' lg='4'>
              <span class='font-weight-bold'>{{ $t('dealer') }} : </span>
              <span>{{ dealerSelected.drugfrom_name }}</span>
            </v-col>
            <v-col cols='12' md='6' lg='4'>
              <span class='font-weight-bold'>{{ $t('tax_number') }} : </span>
              <span>{{ dealerSelected.drugfrom_tax }}</span>
            </v-col>
            <v-col cols='12' md='6' lg='4'>
              <span class='font-weight-bold'>{{ $t('tel') }} : </span>
              <span>{{ dealerSelected.drugfrom_tel }}</span>
            </v-col>
          </v-row>
          <v-divider class='my-1'></v-divider>
          <v-card flat color='transparent' :disabled='!dealerSelected.drugfrom_id'>
            <div class='d-flex align-center justify-space-between my-2'>
              <h4 class='font-weight-bold text-decoration-underline'>
                {{ $t('list_drug_equipment') }}
              </h4>
              <!--              <v-btn color='primary' @click='isAddDrugToCart = true'>-->
              <!--                <v-icon class='me-1'>-->
              <!--                  {{ icons.mdiPlus }}-->
              <!--                </v-icon>-->
              <!--                <span class='d-none d-sm-block'>-->
              <!--                        {{ $t('addDrugAndEquipment') }}-->
              <!--                      </span>-->
              <!--              </v-btn>-->
            </div>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th>{{ $t('code') }}</th>
                  <th style='min-width: 200px'>{{ $t('list') }}</th>
                  <th>{{ $t('tax') }}</th>
                  <th>{{ $t('amount') }}</th>
                  <th>{{ $t('cost_price') }}</th>
                  <th>{{ $t('total_price') }}</th>
                  <th>{{ $t('unit') }}</th>
                  <th class='text-center'>{{ $t('option') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for='(item,index) in cartList' :key='index'>
                  <td>{{ item.purchaseorder_drug_id }}</td>
                  <td style='min-width: 200px'>{{ item.purchaseorder_drug_name }}</td>
                  <td>{{ item.purchaseorder_vat }}%</td>
                  <td>{{ item.purchaseorder_amount }}</td>
                  <td>{{ item.purchaseorder_cost }}</td>
                  <td>{{ item.purchaseorder_total }}</td>
                  <td>{{ item.purchaseorder_drug_unit }}</td>
                  <td class='text-center'>
                    <v-btn icon fab small color='primary' @click='editDrugInPoList(item)'>
                      <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                    </v-btn>
                    <v-btn icon fab small color='error'
                           :disabled='cartList.length <= 1'
                           @click='showConfirmRemoveDrugInPo(item.purchaseorder_id_pri)'>
                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    </v-btn>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

          </v-card>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color='primary' :disabled='!dealerSelected.drugfrom_id || !cartList.length'
                 @click='isConfirmUpdatePo = true'>
            {{ $t('confirm') }}
          </v-btn>
          <v-btn color='secondary' outlined @click="$emit('update:isManagePo',false)">
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
        <v-dialog v-model='isEditDrugInPo' persistent max-width='500'>
          <v-card>
            <v-card-title>
              {{ $t('editDrugAndEquipment') }}
              <v-spacer></v-spacer>
              <div>
                <span>{{ $t('total_price') }} :</span>
                <span class='error--text'>
                  {{ (dataEditDrugInPo.purchaseorder_amount * dataEditDrugInPo.purchaseorder_cost) + (dataEditDrugInPo.purchaseorder_amount * dataEditDrugInPo.purchaseorder_cost * dataEditDrugInPo.purchaseorder_vat / 100) | formatPrice
                  }}
                </span>
              </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-form
              ref='formEditDrugInCart'
              @submit.prevent='editDrugInCart'
            >
              <v-card-text>
                <v-autocomplete
                  v-model='dataEditDrugInPo.drug_id_pri'
                  outlined
                  dense
                  :label="$t('list_drug_equipment')"
                  :items='drugList'
                  hide-details='auto'
                  disabled
                  class='mt-5'
                  :no-data-text="$t('no_information')"
                  item-text='drug_name'
                  item-value='drug_id_pri'
                >
                  <template v-slot:item='{ item }'>
                <span v-if='item.drug_id' class='me-1'>
                  ({{ item.drug_id }})
                </span>
                    <span>
                  {{ item.drug_name }}
                </span>
                  </template>
                  <template v-slot:selection='{ item }'>
                <span v-if='item.drug_id' class='me-1'>
                  ({{ item.drug_id }})
                </span>
                    <span>
                  {{ item.drug_name }}
                </span>
                  </template>
                </v-autocomplete>
                <v-text-field
                  v-model.trim='dataEditDrugInPo.purchaseorder_cost'
                  hide-details='auto'
                  class='mt-5'
                  type='number'
                  :label="$t('cost_price')"
                  outlined
                  :rules='[required]'
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-model.trim='dataEditDrugInPo.purchaseorder_amount'
                  hide-details='auto'
                  class='mt-5'
                  type='number'
                  :label="$t('amount')"
                  outlined
                  :rules='[required]'
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-model.trim='dataEditDrugInPo.purchaseorder_vat'
                  hide-details='auto'
                  class='mt-5'
                  type='number'
                  :label="`${$t('tax')} %`"
                  outlined
                  :rules='[required]'
                  dense
                >
                </v-text-field>

              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color='primary' type='submit' :loading='editCartLoading'
                       :disabled='editCartLoading'>{{ $t('confirm') }}
                </v-btn>
                <v-btn color='secondary' outlined @click='isEditDrugInPo = false'>
                  {{ $t('cancel') }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>

        </v-dialog>
        <v-dialog v-model='isConfirmUpdatePo' persistent max-width='500'>
          <v-card>
            <v-card-title>
              {{ $t('confirmList') }}
            </v-card-title>
            <v-form ref='formConfirmUpdatePo' @submit.prevent='updatePo'>
              <v-card-text>
                <v-dialog
                  ref='dateStart'
                  v-model.trim='isShowDate'
                  :return-value.sync='poData.purchase_expdate'
                  width='290px'
                >
                  <template v-slot:activator='{ on, attrs }'>
                    <v-text-field
                      v-model='poData.purchase_expdate'
                      outlined
                      dense
                      :rules='[required]'
                      :label="$t('dateExpireStart')"
                      v-bind='attrs'
                      v-on='on'
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model.trim='poData.purchase_expdate'
                    :locale='$i18n.locale'
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color='primary'
                      @click='$refs.dateStart.save(poData.purchase_expdate)'
                    >
                      {{ $t('confirm') }}
                    </v-btn>
                    <v-btn
                      text
                      color='primary'
                      @click='isShowDate = false'
                    >
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-date-picker>
                </v-dialog>
                <v-textarea v-model='poData.purchase_comment' rows='3'
                            :label="$t('note')"
                            outlined
                            dense></v-textarea>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color='primary' type='submit'
                       :loading='updatePoLoading'
                       :disabled='updatePoLoading'>
                  {{ $t('confirm') }}
                </v-btn>
                <v-btn color='secondary' outlined @click='isConfirmUpdatePo = false'>
                  {{ $t('cancel') }}
                </v-btn>
              </v-card-actions>
            </v-form>

          </v-card>
        </v-dialog>
        <v-dialog v-model='isRemoveDrugInPo' persistent max-width='300'>
          <v-card>
            <v-card-title>
              <v-spacer></v-spacer>
              <v-icon color='error' class='me-1'>{{ icons.mdiAlertOutline }}</v-icon>
              {{ $t('confirmDeletePo') }}
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color='primary' @click='removeDrugInPo' :loading='loadingDelete'
                     :disabled='loadingDelete'>
                {{ $t('confirm') }}
              </v-btn>
              <v-btn outlined color='secondary' @click='isRemoveDrugInPo = false'>
                {{ $t('cancel') }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref, watch, onMounted } from '@vue/composition-api'
import dealer from '@/api/DrugManagement/dealer'
import poOnline from '@/api/DrugManagement/poOnline'
import { mdiPlus, mdiDeleteOutline, mdiPencilOutline, mdiAlertOutline } from '@mdi/js'
import { required } from '@core/utils/validation'
import store from '@/store'
import { formatPrice } from '@/plugins/filters'

export default {
  model: {
    prop: 'isManagePo',
    event: 'update:isManagePo',
  },
  props: {
    isManagePo: {
      type: Boolean,
      default: false,
    },
    poData: {
      type: Object,
      default: () => ({}),
    },
  },
  filters: {
    formatPrice,
  },
  setup(props, { emit }) {
    const drugfromList = ref([])
    const drugList = ref([])
    const dealerSelected = ref({})
    const cartList = ref([])
    const isConfirmUpdatePo = ref(false)
    const formConfirmUpdatePo = ref(null)
    const isShowDate = ref(false)
    const updatePoLoading = ref(false)
    const isEditDrugInPo = ref(false)
    const dataEditDrugInPo = ref({})
    const formEditDrugInCart = ref(null)
    const editCartLoading = ref(false)
    const isRemoveDrugInPo = ref(false)
    const idRemoveDrugInPo = ref('')
    const loadingDelete = ref(false)

    const showConfirmRemoveDrugInPo = (id) => {
      idRemoveDrugInPo.value = id
      isRemoveDrugInPo.value = true
    }

    const getDrugfromList = async () => {
      const data = await dealer.dealerList()
      drugfromList.value = data
      dealerSelected.value = data.find((item) => item.dealer_id === props.poData.dealer_id)
    }

    const getDrugList = async () => {
      const body = {
        searchtext: '',
        drug_status_id: 1,
        drug_category_id: '',
        druggroup_id: '',
        drugtype_id: '',
        active_page: 1,
        per_page: '0',
      }
      const { data } = await poOnline.poDrugList(body)
      drugList.value = data
    }

    const getCartList = async () => {
      cartList.value = props.poData.purchaseorder
    }

    const updatePo = async () => {
      const isFormValid = formConfirmUpdatePo.value.validate()
      if (!isFormValid) return
      updatePoLoading.value = true

      const res = await poOnline.poUpdate({
        purchase_id_pri: props.poData.purchase_id_pri,
        purchase_expdate: props.poData.purchase_expdate,
        purchase_comment: props.poData.purchase_comment,
      })
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      updateStatusConfirm()
    }

    const editDrugInPoList = (item) => {
      dataEditDrugInPo.value = item
      isEditDrugInPo.value = true
    }

    const editDrugInCart = async () => {
      const isFormValid = formEditDrugInCart.value.validate()
      if (!isFormValid) return
      editCartLoading.value = true
      const res = await poOnline.poUpdate({
        purchase_id_pri: props.poData.purchase_id_pri,
        purchaseorder_id_pri: dataEditDrugInPo.value.purchaseorder_id_pri,
        purchaseorder_vat: dataEditDrugInPo.value.purchaseorder_vat,
        purchaseorder_amount: dataEditDrugInPo.value.purchaseorder_amount,
        purchaseorder_cost: dataEditDrugInPo.value.purchaseorder_cost,
        purchaseorder_total: (dataEditDrugInPo.value.purchaseorder_amount * dataEditDrugInPo.value.purchaseorder_cost) + (dataEditDrugInPo.value.purchaseorder_amount * dataEditDrugInPo.value.purchaseorder_cost * dataEditDrugInPo.value.purchaseorder_vat / 100),
      })

      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      editCartLoading.value = false
      isEditDrugInPo.value = false
    }

    const removeDrugInPo = async () => {
      loadingDelete.value = true
      const res = await poOnline.poRemoveDrugInCartPo(
        props.poData.purchase_id_pri,
        idRemoveDrugInPo.value,
      )
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      isRemoveDrugInPo.value = false
      cartList.value.splice(cartList.value.findIndex((item) => item.purchaseorder_id_pri === idRemoveDrugInPo.value), 1)
      loadingDelete.value = false
    }

    const updateStatusConfirm = async () => {
      const res = await poOnline.poUpdateStatusConfirm({
        purchase_id_pri: props.poData.purchase_id_pri,
        purchase_comment: props.poData.purchase_comment,
      })
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      updatePoLoading.value = false
      emit('update:isManagePo', false)
      emit('onUpdate', false)
    }


    watch(() => props.isManagePo, (val) => {
      if (val) {
        dealerSelected.value = {}
        getCartList()
        getDrugList()
        getDrugfromList()
      }
    })

    return {
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAlertOutline,
      },
      isEditDrugInPo,
      editCartLoading,
      dataEditDrugInPo,
      isRemoveDrugInPo,
      showConfirmRemoveDrugInPo,
      formEditDrugInCart,
      removeDrugInPo,
      editDrugInPoList,
      cartList,
      drugfromList,
      loadingDelete,
      required,
      updatePo,
      getCartList,
      editDrugInCart,
      isConfirmUpdatePo,
      drugList,
      dealerSelected,
      isShowDate,
      formConfirmUpdatePo,
      updatePoLoading,
    }
  },

}
</script>
